import auth from '@/api/services/auth';
import roles from '@/api/services/roles';
import users from '@/api/services/users';
import currencies from '@/api/services/currencies';
import merchants from '@/api/services/merchants';
import wallets from '@/api/services/wallets';
import transactions from '@/api/services/transactions';
import services from '@/api/services/services';
import comissions from '@/api/services/comissions';
import gateways from '@/api/services/gateways';
import permissions from '@/api/services/permissions';
import providers from '@/api/services/providers';
import journals from '@/api/services/journals';
import system_endpoints from '@/api/services/system_endpoints';
import table_permissions from '@/api/services/table_permissions';
import protocols from '@/api/services/protocols';
import security_access_lists from '@/api/services/security_access_lists';
import exchanger_rates from '@/api/services/exchanger_rates';
import exchanger_providers from '@/api/services/exchanger_providers';
import tech_payout from '@/api/services/tech_payouts';
import cascading_templates from '@/api/services/cascading_templates';
import cascading_gateways from '@/api/services/cascading_gateways';
import cascading_filters from '@/api/services/cascading_filters';
import cascading_filter_items from '@/api/services/cascading_filter_items';
import merchant_comissions from '@/api/services/merchant_comissions';
import domain_names from '@/api/services/domain_names';
import payment_forms from '@/api/services/payment_forms';
import invoicing from '@/api/services/invoicing';
import merchant_ips from '@/api/services/merchant_ips';
import registries from '@/api/services/registries';
import api from '@/api';
import reports from '@/api/services/reports';
import reportRegistries from '@/api/services/reportRegistries';
import reportTelegrams from '@/api/services/reportTelegrams';
import reportEmails from '@/api/services/reportEmails';
import reportFormats from '@/api/services/reportFormats';
import payment_cards from '@/api/services/payment_cards';
import cardsGeo from '@/api/services/cardsGeo';
import cardsKeys from '@/api/services/cardsKeys';
import crypto_gateway_providers from '@/api/services/crypto_gateway_providers';
import crypto_gateway_notifications from '@/api/services/crypto_gateway_notifications';
import commissions_fiat from '@/api/services/commissions_fiat';
import commissions_crypto from '@/api/services/commissions_crypto';
import autoSwap from '@/api/services/autoSwap';
import registriesNew from '@/api/services/registriesNew';
import tech_payouts_new from '@/api/services/tech_payouts_new';
import vouchers from '@/api/services/vouchers';
import exchangeCurrencies from '@/api/services/exchangeCurrencies';
import exchangeRateProviders from '@/api/services/exchangeRateProviders';
import exchangeRates from '@/api/services/exchangeRates';
import exchangeRatesOverload from '@/api/services/exchangeRatesOverload';
import exchangeScenarios from '@/api/services/exchangeScenarios';
import exchangePairs from '@/api/services/exchangePairs';
import exchangeWidgets from '@/api/services/exchangeWidgets';
import exchangeOrders from '@/api/services/exchangeOrders';
import merchant_groups from '@/api/services/merchant_groups';
import exchangeBalanceSync from '@/api/services/exchangeBalanceSync';
import serviceFieldTemplates from '@/api/services/serviceFieldTemplates';
import amlProtocols from '@/api/services/amlProtocols';
import amlGateways from '@/api/services/amlGateways';
import amlRules from '@/api/services/amlRules';
import amlChecks from '@/api/services/amlChecks';
import amlQuarantine from '@/api/services/amlQuarantine';
import systemEmails from '@/api/services/systemEmails';
import reportsFiles from '@/api/services/reportsFiles';

const endpoints = {
  ...auth,
  ...roles,
  ...users,
  ...currencies,
  ...merchants,
  ...wallets,
  ...transactions,
  ...services,
  ...comissions,
  ...gateways,
  ...permissions,
  ...providers,
  ...journals,
  ...system_endpoints,
  ...table_permissions,
  ...protocols,
  ...security_access_lists,
  ...exchanger_rates,
  ...exchanger_providers,
  ...tech_payout,
  ...cascading_templates,
  ...cascading_gateways,
  ...cascading_filters,
  ...cascading_filter_items,
  ...merchant_comissions,
  ...domain_names,
  ...payment_forms,
  ...invoicing,
  ...merchant_ips,
  ...registries,
  ...reports,
  ...reportRegistries,
  ...reportTelegrams,
  ...reportEmails,
  ...reportFormats,
  ...payment_cards,
  ...cardsGeo,
  ...cardsKeys,
  ...crypto_gateway_providers,
  ...crypto_gateway_notifications,
  ...commissions_fiat,
  ...commissions_crypto,
  ...autoSwap,
  ...tech_payouts_new,
  ...registriesNew,
  ...vouchers,
  ...exchangeCurrencies,
  ...exchangeRateProviders,
  ...exchangeRates,
  ...exchangeRatesOverload,
  ...exchangeScenarios,
  ...exchangePairs,
  ...exchangeWidgets,
  ...exchangeOrders,
  ...merchant_groups,
  ...exchangeBalanceSync,
  ...serviceFieldTemplates,
  ...amlProtocols,
  ...amlGateways,
  ...amlRules,
  ...amlChecks,
  ...amlQuarantine,
  ...systemEmails,
  ...reportsFiles,
  getData: async (path: string, params: Record<string, any>, loadConfig: Record<string, any>) => {
    return api.get(`/${path}/`, {
      ...loadConfig,
      params,
    });
  },
};

export const useApi = () => {
  return endpoints as typeof endpoints & { [key: string]: (...params: any) => Promise<any> };
};
