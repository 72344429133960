import api from '@/api';
import { EXCHANGE_OFFICE } from './constants';

export default {
  exchangePairsGet: async (params: any) => {
    return await api.get(`/${EXCHANGE_OFFICE}/market_pairs/`, {
      params,
    });
  },
  exchangePairGet: async (params: any) => {
    return await api.get(`/${EXCHANGE_OFFICE}/market_pairs/${params.id}/`);
  },
  exchangePairsSet: async (params: any) => {
    return await api.post(`/${EXCHANGE_OFFICE}/market_pairs/`, params);
  },
  exchangePairsUpdate: async ({ id, ...params }: any) => {
    return await api.patch(`/${EXCHANGE_OFFICE}/market_pairs/${id}/`, params);
  },
  exchangePairsDelete: async ({ id }: any) => {
    return await api.delete(`/${EXCHANGE_OFFICE}/market_pairs/${id}/`);
  },
  exchangePairsSearch: async (params: any) => {
    params = { ...params };
    if (params.q) {
      params.currency_from_iso = params.q.toUpperCase();
      delete params.q;
    }

    return await api.get(`/${EXCHANGE_OFFICE}/market_pairs/`, {
      params,
    });
  },

  exchangePairsMerchantsGet: async ({ pairId, ...params }: any) => {
    return await api.get(`/${EXCHANGE_OFFICE}/market_pairs/${pairId}/merchants/`, {
      params,
    });
  },
  exchangePairsMerchantsSet: async ({ pairId, ...params }: any) => {
    return await api.post(`/${EXCHANGE_OFFICE}/market_pairs/${pairId}/merchants/`, params);
  },
  exchangePairsMerchantsDelete: async ({ pairId, id }: any) => {
    return await api.delete(`/${EXCHANGE_OFFICE}/market_pairs/${pairId}/merchants/${id}/`);
  },

  exchangePairCopy: async ({ pairId }: any) => {
    return await api.post(`/${EXCHANGE_OFFICE}/market_pairs/${pairId}/copy/`);
  },
};
